<template>
  <!-- 提现管理 -->
  <div class="withdrawDepositPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 提现管理详情弹出窗 -->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="48%" :close-on-click-modal="false">
      <DetailsPage details-title="" :detail-obj="withdrawDeposiDetails" :details-item-arr="detailsItemArr" :column="2" border title="" />
    </el-dialog>
    <!-- 审核弹窗 -->
    <Audit ref="auditDialog" :audit-option-item="auditOptionItem" @reviewSubmitted="reviewSubmitted" />
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import Audit from '../../components/Audit'
import DetailsPage from '@/components/DetailsPage'
import { inoutLogPage, inoutLogOutAudit } from '../../api/accountManagementSystem'
export default {
  components: { Audit, FormSearch, Table, Pagination, DetailsPage },
  data() {
    return {
      dialogVisible: false,
      withdrawDeposiDetails: {},
      auditId: '',
      auditOptionItem: [{ label: '通过', value: '01' }, { label: '拒绝', value: '20' }],
      itemData: [
        { label: '会员编码', prop: 'cmpId', width: 120 },
        { label: '会员名称', prop: 'cmpName', width: 180 },
        { label: '结算银行 ', prop: 'settleBankName', width: 180 },
        { label: '金额', prop: 'amount', width: 120, type: 'money' },
        { label: '渠道', prop: 'operPlat', width: 120, child: [{ value: '01', label: '平台' }, { value: '02', label: '银行' }] },
        { label: '收款银行 ', prop: 'openBankName', width: 120 },
        { label: '收款账号 ', prop: 'openAccNo', width: 160 },
        { label: '状态', prop: 'state', width: 120, child: [{ value: '01', label: '审核通过' }, { value: '02', label: '待平台审核' }, { value: '10', label: '待银行审核' }, { value: '11', label: '银行已拒绝' }, { value: '20', label: '平台已拒绝' }] }
      ],
      operationButton: [
        { num: '02', val: 'state', bType: 'primary', label: '审核', handleEvent: this.auditRow },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      formItemArr: [
        { type: 'select', label: '结算银行', value: 'settleBankId', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('settleBankName') },
        { type: 'input', label: '会员名称', value: 'cmpName' }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        inorout: 2
      },
      detailsItemArr: [
        { label: '会员名称', value: 'cmpName' },
        { label: '结算银行', value: 'settleBankName' },
        { label: '渠道', value: 'operPlat', child: [{ id: '01', val: '平台' }, { id: '02', val: '银行' }] },
        { label: '提现金额(元)', money: 'amount' },
        { label: '提现手续费(元)', value: 'feeAmt' },
        { label: '手续费支付方', value: 'feePayWay', child: [{ id: 1, val: '平台' }, { id: 2, val: '会员' }] },
        { label: '收款银行', value: 'openBankName' },
        { label: '收款账号名称', value: 'openAccName' },
        { label: '收款账号', value: 'openAccNo' },
        { label: '收款网点', value: 'openBankNet' },
        { label: '状态', value: 'state', child: [{ id: '01', val: '审核通过' }, { id: '02', val: '待平台审核' }, { id: '10', val: '待银行审核' }, { id: '11', val: '银行已拒绝' }, { id: '20', val: '平台已拒绝' }] },
        { label: '备注', value: 'remark' }
      ]
    }
  },
  methods: {
    // 点击审核按钮
    reviewSubmitted(value) {
      value.id = this.auditId
      inoutLogOutAudit(value, res => {
        this.$message.success(res.msg)
        this.$refs.auditDialog.dialogAuditFormVisible = false
        this.getdata(true)
      })
    },
    auditRow(row) {
      this.auditId = row.id
      this.$refs.auditDialog.dialogAuditFormVisible = true
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          inorout: 2
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inoutLogPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 查看资金明细
    viewDetails(row) {
      this.dialogVisible = true
      this.withdrawDeposiDetails = { ...row }
    }
  }
}
</script>

<style scoped lang="scss">
.withdrawDepositPageBox {
  width: 100%;
  .topUpButton {
    float: right;
    height: 42px;
  }
  .goBackButton {
    margin: 24px 48%;
  }
  .el-descriptions {
    padding: 0 18px;
  }
}
</style>
